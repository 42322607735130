<script lang="ts" setup>
import { vElementVisibility } from "@vueuse/components";
import VDisclaimer from "@magnit/core/src/containers/VDisclaimer/VDisclaimer.vue";
import type { ITicker } from "~/typings/api/content";

const props = defineProps<{
  ticker: Nullable<ITicker>;
  pending: boolean;
}>();
const emit = defineEmits<{
  onVisibility: [];
  onClick: [];
}>();

const backgroundImageUrl = computed(
  () => props.ticker && `url(${props.ticker.publicImageURL})`,
);

const onVisibility = (v: boolean) => {
  if (v) {
    emit("onVisibility");
  }
};
const onClick = () => {
  emit("onClick");
};
</script>

<template>
  <AppTickerSkeleton v-if="pending" :class="$attrs.class" />
  <section
    v-else-if="ticker"
    v-element-visibility="onVisibility"
    class="ticker"
  >
    <NuxtLink
      :to="ticker.offerURL"
      class="ticker-link"
      :aria-label="ticker.disclaimer"
      @click="onClick"
    />
    <VWrapperLocal style="position: relative">
      <VDisclaimer :content="ticker.disclaimer" />
    </VWrapperLocal>
  </section>
</template>

<style lang="postcss" scoped>
.ticker {
  width: 100%;
  position: relative;
  overflow: hidden;

  /* min-height прописана чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 60px;

  @media (--pl-viewport-from-l) {
    min-height: 90px;
  }

  & :deep(.disclaimer) {
    bottom: var(--pl-unit-x1);
    right: var(--pl-unit-x5);

    @media (--pl-viewport-from-m) {
      right: var(--pl-unit-x8);
    }

    @media (--pl-viewport-from-l) {
      bottom: var(--pl-unit-x2);
    }
  }
}

.ticker-link {
  display: block;
  height: 60px;
  width: 9090px;
  background-image: v-bind(backgroundImageUrl);
  background-repeat: repeat-x;
  background-size: contain;
  animation: slide 75s linear infinite;

  @media (--pl-viewport-from-l) {
    height: 90px;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-4545px);
  }
}
</style>
