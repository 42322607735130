import { urls } from "~/api/config";
import { getCachedData } from "~/utils/cached-data";
import type { IMainSlide, ITicker } from "~/typings/api/content";

export default () => {
  const commonParams = {
    lazy: true,
    getCachedData: getCachedData,
  };

  const requestMainSliderSlides = () =>
    useTransport<IMainSlide[]>(urls.content.mainSlides, commonParams);

  const requestTicker = () => useTransport<ITicker, ITicker>(urls.content.ticker, commonParams);

  return {
    requestMainSliderSlides,
    requestTicker,
  };
};
