<script setup lang="ts">
import type { IResponsiveOnly } from "@magnit/core/types";
import type { ISwiperOptions } from "~/typings/components/appSlider";
import contentApi from "~/api/contentApi";

defineProps<{
  title?: string;
  swiperBreakpoints?: IResponsiveOnly<ISwiperOptions>;
  lesser?: boolean;
}>();

const { requestMainSliderSlides } = contentApi();
const { status, data: slides } = await requestMainSliderSlides();
</script>

<template>
  <AdfoxSliderTopSlider
    :loading="status === 'pending'"
    :slides="slides || []"
    :title="title"
    :swiper-breakpoints="swiperBreakpoints"
    :lesser="lesser"
    slider-test-id="adfox"
  />
</template>
