<script setup lang="ts">
import { Swiper as SwiperClass } from "swiper";
import { ref } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import type { IResponsiveOnly } from "@magnit/core/types";
import VText from "@magnit/core/src/components/VText/VText.vue";
import type { ISwiperOptions } from "~/typings/components/appSlider";
import { IYaSlotApiStatus, useSlotsStore } from "~/store/useSlotsStore";
import type { IAdfoxSlide } from "~/typings/components/adfoxSlider";

const props = withDefaults(
  defineProps<{
    loading?: boolean;
    slides: IAdfoxSlide[];
    title?: string;
    swiperBreakpoints?: IResponsiveOnly<ISwiperOptions>;
    sliderTestId?: string;
  }>(),
  {
    title: "",
    swiperBreakpoints: () => ({
      xs: {
        slidesPerView: "auto",
        spaceBetween: 8,
        pagination: {
          enabled: true,
        },
        slidesOffsetAfter: 12,
        slidesOffsetBefore: 12,
      },
      s: {
        slidesPerView: "auto",
        spaceBetween: 12,
        pagination: {
          enabled: true,
        },
        slidesOffsetAfter: 20,
        slidesOffsetBefore: 20,
      },
      m: {
        slidesPerView: "auto",
        spaceBetween: 20,
        pagination: {
          enabled: true,
        },
        slidesOffsetAfter: 32,
        slidesOffsetBefore: 32,
      },
      l: {
        slidesPerView: "auto",
        spaceBetween: 24,
        pagination: {
          enabled: true,
        },
        navigation: true,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
      xl: {
        slidesPerView: 2,
        spaceBetween: 24,
        pagination: {
          enabled: true,
        },
        navigation: true,
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
    }),
  },
);

const emit = defineEmits<{
  onVisibilityChange: [isVisible: boolean];
}>();

defineOptions({ inheritAttrs: false });
const attrs = useAttrs();

const { send } = useAnalytics();
const { adfoxOwnerId } = usePublicConfig();
const slotApi = useSlotsStore();
const slotError = ref(false);
const swiper = ref<Nullable<SwiperClass>>();

const skipSlides = ref<IAdfoxSlide["id"][]>([]);
watch(
  () => props.slides,
  () => {
    skipSlides.value = [];
  },
);
const visibleSlides = computed(() =>
  props.slides.filter((slide) => !skipSlides.value.includes(slide.id)),
);

const isSkeletonVisible = computed(
  () => props.loading || !slotApi.slotApiLoaded,
);
const isSlidesVisible = computed(
  () => !isSkeletonVisible.value && Boolean(visibleSlides.value?.length),
);
const onStubAdfox = (slide: IAdfoxSlide) => {
  skipSlides.value.push(slide.id);
};
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("Sections:Adfox:View");
  }
  emit("onVisibilityChange", isIntersecting);
};
const onSwiper = (s: SwiperClass) => (swiper.value = s);
</script>

<template>
  <div
    v-if="$slots.skeleton && isSkeletonVisible"
    class="adfox-slider-skeleton-wrapper"
  >
    <slot name="skeleton" />
  </div>
  <section
    v-if="isSlidesVisible"
    v-intersection-observer="onVisibility"
    class="adfox-slider-wrapper"
    v-bind="attrs"
  >
    <div class="adfox-slider">
      <VWrapperLocal v-if="title">
        <VText
          tag="div"
          :font="{ xs: 'headline-small', l: 'headline-large' }"
          class="adfox-slider__title"
        >
          {{ title }}
        </VText>
      </VWrapperLocal>
      <VWrapper :indent="{ l: '32px' }">
        <AppSlider
          autoplay
          :breakpoints="swiperBreakpoints"
          class="slider"
          :data-test-id="sliderTestId"
          @on-swiper="onSwiper"
        >
          <AppSliderSlide
            v-for="(slide, index) in visibleSlides"
            :key="slide.id"
            :size="{ xs: 12, s: 12, m: 8, ml: 8, l: 8, xl: 6 }"
            class="slider-slide"
          >
            <VYaSlot
              :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
              :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
              :owner-id="adfoxOwnerId"
              :config="slide.payload"
              :analytics-sender="send"
              :analytics-view="{
                name: 'Sections:Adfox:Item:View',
                options: {
                  position: index + 1,
                },
              }"
              :analytics-click="{
                name: 'Sections:Adfox:Item:Click',
                options: {
                  position: index + 1,
                },
              }"
              with-hover
              @error="slotError = true"
              @stub="onStubAdfox(slide)"
            />
          </AppSliderSlide>
        </AppSlider>
      </VWrapper>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
.adfox-slider-wrapper {
  & :deep(.slider-navigation) {
    background: white;
  }
}

.adfox-slider {
  &__title {
    margin: 38px 0 14px;
  }
}

.slider-slide {
  overflow: hidden;
  border-radius: var(--pl-unit-x5);

  & :deep(.unit-ad-block-message) {
    aspect-ratio: 1.8;
  }

  & :deep(.unit-disclaimer) {
    position: absolute;
    bottom: var(--pl-unit-x2);
    right: var(--pl-unit-x5);
  }

  @media (--pl-viewport-from-m) {
    border-radius: var(--pl-unit-x6);
  }

  @media (--pl-viewport-from-ml) {
    border-radius: var(--pl-unit-x7);
  }

  @media (--pl-viewport-from-l) {
    border-radius: var(--pl-unit-x8);

    & :deep(.unit-disclaimer) {
      right: var(--pl-unit-x6);
    }
  }
}
</style>
